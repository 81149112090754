$(document).ready(function() {
    $(window).on('mdtracking', function(event) {
        let dataLayer = event.target.dataLayer;
        
        setTimeout(function() {
            for (let i = 0; i < dataLayer.length; i++) {
                if (dataLayer[i].event == "mdtracking") {
                    $.ajax({
                        url: "assets/ajax/localEventLogger.php",
                        type: "POST",
                        data: JSON.stringify(dataLayer[i]),
                        contentType: "application/json",
                        dataType: "json",
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest'
                        },
                        success: function(response) {
                            console.log('Success:', response);
                            dataLayer.splice(i, 1);
                        },
                        error: function(jqXHR, textStatus, errorThrown) {
                            console.error('Error:', textStatus, errorThrown);
                            if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
                                console.error('Server message:', jqXHR.responseJSON.message);
                            }
                        }
                    });
                }
            }
        }, 750);
    });
});